import React from "react";
import {Link} from "gatsby";
import Img from "gatsby-image";

const IconWrapper = ({useContainer, children}) => (
        (useContainer)
            ? <div className={"tileIconContainer"}>{children}</div>
            : <>{children}</>
);

const ALTile = ({className, icon, iconContainer, iconType = "fixed", iconAlt = "ALMobile Solution", mainText, subText, customBtn, useWebinar = true, webinarLink}) => {
    return (
        <div className={`tile ${(className) ? className : ""}`}>
            {
               <IconWrapper
                   useContainer={iconContainer}
               >
                   {
                       ((icon)
                               ?
                               ((iconType === "fixed")
                                       ?
                                       <Img
                                           style={{ display: "block" }}
                                           className={"tileIcon"}
                                           alt={iconAlt}
                                           fixed={icon}
                                       />
                                       :
                                       <Img
                                           style={{ display: "block" }}
                                           className={"tileIcon"}
                                           alt={iconAlt}
                                           fluid={icon}
                                       />
                               )
                               :
                               <></>
                       )
                   }
               </IconWrapper>
            }
            {
                (typeof mainText === "string")
                    ? <div className={"tileMainText"}>{mainText}</div>
                    : mainText
            }
            {
                (typeof subText === "string")
                    ? <div className={"tileSubText"}>{subText}</div>
                    : subText
            }
            {
                (customBtn) && customBtn
            }
            {
                (useWebinar) && <Link className={"tileWebinarLink btn"} to={`/events/`} state={{component: "webinars"}}>Watch Webinar</Link>
            }
        </div>
    )
};

export default ALTile;
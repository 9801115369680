import React, { useState, useRef } from "react";
import {Link} from "gatsby";

import SearchBar from "./searchbar";
import SubOptions from "./SubOptions";

function NavComp ({active, navData, dataIndex, enterCallback = _ => "", leaveCallback = _ => ""}) {
    const {label, link} = navData;
    const showSubOptions = e => enterCallback(e);

    const hideSubOptions = e => leaveCallback(e);

    return (
        (link)
            ?
            <Link
                index={dataIndex}
                className={`link ${(active === dataIndex) ? "active" : ""}`}
                onMouseEnter={showSubOptions}
                onMouseLeave={hideSubOptions}
                to={link}
            >
                {label}
            </Link>
            :
            <div
                index={dataIndex}
                data-id={label}
                className={`link ${(active === dataIndex) ? "active" : ""}`}
                onMouseEnter={showSubOptions}
                onMouseLeave={hideSubOptions}
            >
                {label}
            </div>
    );
}

function NavBarDesktop({options}) {
    const navOptions = [...options];
    const [searchActive, setSearchActive] = useState(false);
    const [activeElem, setActiveElement] = useState(-1);

    const activateSearch = (isSearchActive) => setSearchActive(isSearchActive);

    const showSubOptions = activeRef => {
        const optionIndex = activeRef.currentTarget.getAttribute("index");
        setActiveElement(parseInt(optionIndex));
    };

    const hideSubOptions= _ => {
        setActiveElement(-1);
    };

    return (
        <div className={"navbar desktop"}>
            <div className={"generalOptions"}>
                <span className={`linkContainer ${(!searchActive) ? "" : "inactive"}`}>
                    <Link className={"link"} to="/careers/">Careers</Link>
                </span>
                <span className={`linkContainer ${(!searchActive) ? "" : "inactive"}`}>
                    <Link className={"link"} to="/contactus/">Contact Us</Link>
                </span>
                <span className={`linkContainer ${(!searchActive) ? "" : "inactive"}`}>
                    <a role="button" className={"link"} href="tel:8552725555">855.272.5555</a>
                </span>
                <SearchBar
                    onClick={activateSearch}
                />
            </div>
            <div className={"pageOptions"}>
                {
                    navOptions.map( (elem, index) => {
                        if(elem.label !== "More") {
                            return (
                                <NavComp
                                    key={index}
                                    navData={elem}
                                    dataIndex={index}
                                    enterCallback={showSubOptions}
                                    leaveCallback={hideSubOptions}
                                    active={(activeElem)}
                                />
                            )
                        }
                    })
                }
            </div>
            <SubOptions
                index={activeElem}
                enterCallback={showSubOptions}
                leaveCallback={hideSubOptions}
                subOptions={(activeElem !== -1) ? navOptions[activeElem].subOptions : []}
            />
        </div>
    );
}

export default NavBarDesktop;
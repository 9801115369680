import React from "react";
import Img from "gatsby-image"

const NestedContentRow = ({customProps=[], iconData={icon: null, iconCaption:"",iconAlt:"ALMobile Icon", iconType: "fixed"}, videoData={videoSrc: null, videoPoster: null}, textData={}, link}) => {
    return (
        <div
            className={"nestedContentContainer"}
            {
                ...customProps
            }
        >
            <div className={"nestedIconContainer"}>
                {
                    ((iconData.icon)
                        ?
                            ((iconData.iconType === "fixed")
                            ?
                                <Img
                                    style={{ display: "block" }}
                                    className={"nestedIcon"}
                                    alt={iconData.iconAlt}
                                    fixed={iconData.icon}
                                />
                            :
                                <Img
                                    style={{ display: "block" }}
                                    className={"nestedIcon"}
                                    alt={iconData.iconAlt}
                                    fluid={iconData.icon}
                                />
                            )
                        : (videoData.videoSrc) ?
                        <video
                            controls
                            poster={videoData.videoPoster}
                        >
                            <source src={videoData.videoSrc}/>
                        </video>
                        : <></>
                    )
                }
                {
                    (typeof iconData.iconCaption === "string")
                        ? <div className={"nestedIconCaption"}>{iconData.iconCaption}</div>
                        : iconData.iconCaption
                }
            </div>
            <div className={"textContainer"}>
                {
                    (typeof textData.level1 === "string")
                        ? <div className={"level1 text"}>{textData.level1}</div>
                        : textData.level1
                }
                {
                    (typeof textData.level2 === "string")
                        ? <div className={"level2 text"}>{textData.level2}</div>
                        : textData.level2
                }
                {
                    (typeof textData.level3 === "string")
                        ? <div className={"level3 text"}>{textData.level3}</div>
                        : textData.level3
                }
            </div>
            {(link ? (typeof link === "string" ? <div className={"link btn"}>{link}</div>: link) : <></>)}
        </div>
    )
};

export default NestedContentRow;
import React, { useState } from "react";


const ALInput = ({formReg, disabled, inputLabel, inputType="text", inputName="", inputPlaceHolder=""}) => {
    const [inputActive, toggleActive] = useState(false);
    return (
        <div className={"ALInputContainer"}>
            <div className={"ALInputLabel"}>{(inputLabel) && inputLabel}</div>
            <div className={`ALInput ${inputActive ? "active" : ""}`}>
                {
                    (inputLabel) &&
                    <input
                        disabled={disabled}
                        ref={formReg}
                        name={inputName}
                        type={inputType}
                        placeholder={inputPlaceHolder}
                        onBlur={ _ => toggleActive(false)}
                        onFocus={ e => {
                            toggleActive(true)
                        }}
                        onChange={ e=> {
                            e.currentTarget.classList.remove("error");
                        }}
                    />
                }
            </div>
        </div>
    )

};

export default ALInput;
import React, { useState } from "react";
import ALDialog from "./ALDialog";

const ALSubscribe = _ => {
    const [dialogShown, toggleDialog] = useState(false);

    return (
        <div className={"contentRow SubscribeRow"}>
            <div className={"subContentRow"}>
                <div className={"textContainer"}>
                    <div className={"level1 text"}>
                        Get our weekly updates and fresh ideas delivered to your inbox.
                    </div>
                </div>
                <div
                    className={"subscribeBtn"}
                    onClick={ _ => toggleDialog(true)}
                    onKeyUp={ _ => toggleDialog(true)}
                >
                    SUBSCRIBE
                </div>
            </div>
            {
                (dialogShown) &&
                <ALDialog
                    header={"Stay Informed!"}
                    dialogType={"subscribe"}
                    dialogFields={[
                        {
                            type: "input",
                            inputName: "First Name",
                            inputLabel: "First Name",
                            inputType: "text",
                            inputPlaceHolder: ""
                        },
                        {
                            type: "input",
                            inputLabel: "Last Name",
                            inputName: "Last Name",
                            inputType: "text",
                            inputPlaceHolder: ""
                        },
                        {
                            type: "input",
                            inputLabel: "Email*",
                            inputName: "Email",
                            inputType: "text",
                            inputPlaceHolder: "",
                            required: true
                        }
                    ]}
                    onClose={toggleDialog}
                />
            }
        </div>
    )
};

export default ALSubscribe;
import React, { useState } from "react";
import { graphql, useStaticQuery, Link} from "gatsby";
import Img from "gatsby-image";


const SearchItem = ({itemData}) => {
    const {label, link} = itemData;

    return (
        <Link
            className={"listItem link"}
            to={link}
        >
            {label}
        </Link>
    )
};

const SearchResults = ({searchValue}) => {
    const searchItems = [
        {
            label: "Time Tracking",
            link: "/almobiletimetracking/"
        },
        {
            label: "Production Tracking",
            link: "/almobileproductiontracking/"
        },
        {
            label: "Attendance Tracking",
            link: "/almobileattendancetracking/"
        },
        {
            label: "Contact",
            link: "/contactus/"
        },
        {
            label:"Careers",
            link: "/careers/"
        },
        {
            label:"Culture",
            link: "/careers/"
        },
        {
            label:"Employee benefits",
            link: "/careers/"
        },
        {
            label: "College program",
            link: "/careers/"
        },
        {
            label: "Users Conference",
            link: "/events/"
        },
        {
            label: "Conference",
            link: "/events/"
        },
        {
            label: "Webinars",
            link: "/events/"
        },
        {
            label: "Blog",
            link: "/blog&press/"
        },
        {
            label: "Press release",
            link: "/blog&press/"
        },
        {
            label: "Press coverage",
            link: "/blog&press/"
        },
        {
            label: "Frequently asked Questions",
            link: "/almobiletimetracking/"
        },
        {
            label: "FAQs",
            link: "/almobiletimetracking/"
        },
        {
            label: "Kiosk",
            link: "/almobileattendancetracking/"
        },
        {
            label: "Biometric",
            link: "/almobileattendancetracking/"
        },
        {
            label: "Multi-lingual",
            link: "/almobiletimetracking/"
        },
        {
            label:"ERP",
            link: "/"
        },
        {
            label:"Industries",
            link: "/"
        },
        {
            label:"ALMobile support",
            link: "/contactus/"
        },
        {
            label:"Support service",
            link: "/contactus/"
        },
        {
            label:"Technology partners",
            link: "/"
        },
        {
            label:"testimonials",
            link: "/"
        },
        {
            label:"integration",
            link: "/"
        },
        {
            label: "videos",
            link: "/almobiletimetracking/"
        },
        {
            label: "sales",
            link: "/contactus/"
        },
    ];
    searchValue = searchValue.toLowerCase();

    if(searchValue.length === 0 || !searchValue){
        return <></>
    }

    return (
        <div
            className={"searchResults"}
            onMouseDown={event => event.preventDefault()}
        >
            {
                searchItems.map((elem, index) => {
                    if(elem.label.toLowerCase().indexOf(searchValue) !== -1) {
                        return <SearchItem
                            key={index}
                            itemData={elem}
                        />
                    }
                })
            }
        </div>
    )
};

const SearchBar = ({onClick: stateClick = _ => ""}) => {
    const data = useStaticQuery(graphql`
        query {
            placeholderImage: file(relativePath: { eq: "searchIcon.png" }) {
                childImageSharp {
                    fixed(width: 25, height: 25) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `);
    const [searchActive, setSearchActive] = useState(false),
        [searchVal, setSearchVal] = useState("");

    function activateSearch() {
        if(searchActive) {
            setSearchActive(false);
            stateClick(false);
            return;
        }
        setSearchActive(true);
        stateClick(true);
    }

    function deactivateSearch(e) {
        e.currentTarget.value = '';
        setSearchVal(e.currentTarget.value);
    }

    function inputSearch(e) {
        const keycode = e.which || e.keyCode;
        if(keycode === 27){
            if(!e.currentTarget.value) {
                setSearchActive(false);
                stateClick(false);
            }
            e.currentTarget.value = '';
        }
        setSearchVal(e.currentTarget.value);
    }

    return (
        <div className={`searchContainer`}>
            <div className={`searchFields ${(searchActive) ? "active" : ""}`}>
                <input
                    id={"searchInput"}
                    className={`${(searchActive) ? "active" : ""}`}
                    autoComplete="off"
                    type="text"
                    placeholder={"search"}
                    onKeyUp={inputSearch}
                    onBlur={deactivateSearch}
                />
                <SearchResults
                    searchValue={searchVal}
                />
            </div>
            <div
                role={"button"}
                tabIndex={0}
                className={"searchIcon"}
                onClick={activateSearch}
                onKeyUp={activateSearch}
            >
                <Img
                    fixed={data.placeholderImage.childImageSharp.fixed}
                    alt={"ALMobile Search"}
                />
            </div>
        </div>
    )
};

export default SearchBar;
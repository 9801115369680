import React, { useState } from "react";
import {Link} from "gatsby";

const Webinar = _ => {
    const [showContent, setContactState] = useState(false);

    function showTab() {
        setContactState(!showContent);
    }

    return (
        <div
            id={"webinarContainer"}
            className={`${ (showContent) ? "active" : ""}`}
        >
            <div
                id={"webinarTabContainer"}
                onClick={showTab}
                onKeyUp={showTab}
            >
                <div
                    id={"webinarTab"}
                >
                    See ALMobile Live!
                </div>
            </div>
            <div
                id={"webinarTabContentContainer"}
                className={`${ (showContent) ? "active" : ""}`}
            >
                <div className={"webinarTabContent"}>
                    <div className={"textContainer"}>
                        <div className={"level2"}>
                            Revolution the Way you do Business
                        </div>
                        <div className={"level3"}>
                            Click the button to join our next ALMobile webinar!
                        </div>
                    </div>
                </div>
                <a
                    className={"webinarTabContent link"}
                    href={"https://register.gotowebinar.com/register/8244948930313538817"}
                    target={"_blank"}
                    rel={"noreferrer noopener"}
                >
                    Join Now!
                </a>
            </div>
        </div>
    )
};

export default Webinar;
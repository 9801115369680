import { graphql, useStaticQuery, Link } from "gatsby";
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react";
import Img from "gatsby-image"

import NavBarDesktop from "./navbardesktop";
import NavBarMobile from "./navbarMobile";

function Header() {
    const data = useStaticQuery(graphql`
        query {
            placeholderImage: file(relativePath: { eq: "ALMobileLogo.png" }) {
                childImageSharp {
                    fixed(width: 105, height: 105) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `);
    const navOptions = [
        {
            label: "Products",
            subOptions: [
                {
                    label: "Time Tracking",
                    link: "/almobiletimetracking/",
                    componentID: "timeTracking"
                },
                {
                    label: "Production Tracking",
                    link: "/almobileproductiontracking/",
                    componentID: "productionTracking"
                },
                {
                    label: "Attendance Tracking",
                    link: "/almobileattendancetracking/",
                    componentID: "attendanceTracking"
                }
            ]
        },
        {
            label: "Events",
            link: "/events/",
            subOptions: [
                {
                    label: "Webinars",
                    link: "/events/",
                    componentID: "webinars"
                },
                {
                    label: "Tradeshows & Conferences",
                    link: "/events/",
                    componentID: "tradeShowsConferences"
                },
                {
                    label: "ALMobile Users Conferences",
                    link: "/events/",
                    componentID: "usersConferences"
                }
            ]
        },
        {
            label: "Blog & Press",
            link: "/blog&press/",
            subOptions: [
                {
                    label: "Blog",
                    link: "/blog&press/",
                    componentID: "blog"
                },
                {
                    label: "Press Coverage",
                    link: "/blog&press/",
                    componentID: "pressCoverage"
                },
                {
                    label: "Press Release",
                    link: "/blog&press/",
                    componentID: "pressRelease"
                },

            ]
        },
        {
            label: "More",
            subOptions: [
                {
                    label: "Careers",
                    link: "/careers/",
                    componentID: "careers"
                },
                {
                    label: "Contact Us",
                    link: "/contactus/",
                    componentID: "contactus"
                },
            ]
        }
    ];
    const [isMobile, browserIsMobile] = useState(false);

    const checkBrowserSize = _ => {
        if(window.innerWidth <= 1366){
            return browserIsMobile(true);
        }
        browserIsMobile(false);
    };

    useEffect( _ => {
        checkBrowserSize();
        window.addEventListener("resize", checkBrowserSize);
        return _ => {
            window.removeEventListener("resize", checkBrowserSize);
        };
    });

    return (
        <header>
            <div id={"headerContainer"}>
                <div id="headerImage">
                    <Link
                        to={"/"}
                        >
                        <Img
                            fixed={data.placeholderImage.childImageSharp.fixed}
                            alt="ALMobile Logo"
                        />
                    </Link>
                </div>
                {(!isMobile)
                    ? <NavBarDesktop options={navOptions}/>
                    : <NavBarMobile options={navOptions}/>
                }
            </div>
        </header>
    );
}

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;

import React, { useState } from "react";
import { useForm } from "react-hook-form";

const ALTextarea = ({formReg, disabled, inputLabel, inputName=""}) => {
    const [inputActive, toggleActive] = useState(false);
    return (
        <div className={"ALTextareaContainer"}>
            <div className={"ALTextareaLabel"}>{(inputLabel) && inputLabel}</div>
            <div className={`ALTextarea ${inputActive ? "active" : ""}`}>
                {
                    (inputLabel) &&
                    <textarea
                        disabled={disabled}
                        ref={formReg}
                        name={inputName}
                        onBlur={ _ => toggleActive(false)}
                        onFocus={ _ => toggleActive(true)}
                        onChange={ e => {
                            e.currentTarget.classList.remove("error");
                        }}
                    >
                    </textarea>
                }
            </div>
        </div>
    )
};

export default ALTextarea;
/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react";
import PropTypes from "prop-types";
import Header from "./header";
import Footer from "./footer";
import ALSubscribe from "./ALSubscribe";
import Webinar from "./webinar";

import "../scss/reset.scss";
import "../scss/page.scss";

const Layout = ({ pageID="", children }) => {
    return (
        <>
            <Header/>
            <Webinar/>
            <div id={"pageContainer"}>
                <main id={pageID}>{children}</main>
                <ALSubscribe/>
                <Footer />
            </div>
        </>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;

import React, { useEffect, useState } from "react";
import {useForm} from "react-hook-form";

import ALInput from "./formInputs/ALInput";
import ALButton from "./formInputs/ALButton";
import ALTextarea from "./formInputs/ALTextarea";
import ALSelect from "./formInputs/ALSelect";
import ALGoogleRecaptcha from "./formInputs/ALGoogleRecaptcha";

const ALDialog = ({ dialogFields, dialogType, header, onClose, onSave = _ => ""}) => {
    const {handleSubmit, triggerValidation, register, reset, errors, setValue} = useForm();
    const [isValid, validateForm] = useState(false);
    const [isSubmitted, setFormState] = useState(false);
    const [response, setResponse] = useState({});
    const [disallowSubmit, submitState] = useState(true);
    const requiredArray = ["token"];

    const formSubmit = async _ => {
        const valid = await triggerValidation(requiredArray);
        if(!valid) {
            Object.keys(errors).map(elem => {
                errors[elem].ref.classList.add("error");
                errors[elem].ref.placeholder = "Required Field";
            });
        }
        validateForm(valid);
    };
    const onSubmit = async data => {
        if(isValid) {
            try {
                const sendData = {
                    formType: dialogType,
                    formFields: {...data}
                };
                const formResponse = await fetch(process.env.GATSBY_API_URL, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(sendData),
                });
                const servResp = await formResponse.json();
                reset();
                onSave();
                setFormState(true);
                if(!servResp.result) {
                    return setResponse(servResp);
                }
                setResponse(servResp);
            } catch (error) {
                setFormState(true);
            }
        }
    };
    const captchaReady = _ => submitState(false);

    useEffect( _ => {
        ALGoogleRecaptcha({
            placeHolder: "dialogBox",
            formReg: register,
            captchaReady: captchaReady,
            regSet: setValue,
            actionType: "ALDialog"
        });
    }, []);

    return (
        <div id={"dialogBox"}>
            <div className={"subContentRow"}>
                <div
                    className={"dialogClose"}
                    onClick={ _ => onClose(false)}
                >
                    <div className={`bar1`}></div>
                    <div className={`bar2`}></div>
                </div>
                <div className={"header"}>{header}</div>
                {
                    (!isSubmitted) ?
                    <form
                        autoComplete={"off"}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        {
                            dialogFields.map((elem, index) => {
                                if(elem.required) requiredArray.push(elem.inputName);
                                if(elem.type === "input"){
                                    return (
                                        <ALInput
                                            key={index}
                                            formReg={register({
                                                required: elem.required
                                            })}
                                            {...elem}
                                        />
                                    )
                                }

                                if(elem.type === "textarea") {
                                    return (
                                        <ALTextarea
                                            key={index}
                                            formReg={register({
                                                required: elem.required
                                            })}
                                            {...elem}
                                        />
                                    )
                                }

                                if(elem.type === "select") {
                                    return (
                                        <ALSelect
                                            key={index}
                                            formReg={register({
                                                required: elem.required
                                            })}
                                            {...elem}
                                        />
                                    )
                                }
                            })
                        }
                        <ALButton
                            disabled={disallowSubmit}
                            handleSubmit={formSubmit}
                        />
                    </form>
                    :
                    <div className={"dialogResponse"}>
                        {(response.result) ? "Thank you" : response.message}
                    </div>
                }
                <div id={"g-captcha"}>
                    This site is protected by reCAPTCHA and the Google
                    <a href="https://policies.google.com/privacy">Privacy Policy</a> and
                    <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                </div>
            </div>
        </div>
    )
};

export default ALDialog;
import React from "react";

const ALGoogleRecaptcha = ({placeHolder = "pageContainer", captchaReady, formReg, regSet, actionType}) => {
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.GATSBY_CLIENT_KEY}`;
    document.getElementById(placeHolder).appendChild(script);
    script.addEventListener("load", function() {
        window.grecaptcha.ready(_ => {
            window.grecaptcha
                .execute(process.env.GATSBY_CLIENT_KEY, { action: actionType })
                .then(token => {
                    formReg({ name: "token" });
                    captchaReady(true);
                    regSet("token", token);
                });
        });
    });
};

export default ALGoogleRecaptcha;
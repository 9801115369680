import React from "react";
import {graphql, useStaticQuery, Link} from "gatsby";
import Img from "gatsby-image";

const Footer = _ => {
    const data = useStaticQuery(graphql`
        query {
            instagram: file(relativePath: { eq: "instagram.png" }) {
                childImageSharp {
                    fixed(width: 35, height: 35) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }

            twitter: file(relativePath: { eq: "twitter.png" }) {
                childImageSharp {
                    fixed(width: 35, height: 35) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            
            facebook: file(relativePath: { eq: "facebook.png" }) {
                childImageSharp {
                    fixed(width: 35, height: 35) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }

            linkedin: file(relativePath: { eq: "linkedin.png" }) {
                childImageSharp {
                    fixed(width: 35, height: 35) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }

            vimeo: file(relativePath: { eq: "vimeo.png" }) {
                childImageSharp {
                    fixed(width: 35, height: 35) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `);
    return(
        <footer>
            <div id={"footerHeader"}>
                <div id={"footerGeneralOptions"}>
                    <Link className={"link"} to={"/careers/"}>Careers</Link>
                    <Link className={"link"} to={"/events/"}>Events</Link>
                    <Link className={"link"} to={"/blog&press/"} state={{component: "blog"}}>Blog</Link>
                </div>
            </div>
            <div id={"footerBody"}>
                <div id={"footerInfoMainText"} className={"footerInfo"}>
                    <a
                        className={"infoItem link"}
                        href={"https://austinlane.com/"}
                        target={"_blank"}
                        rel={"noreferrer noopener"}
                    >
                        Austin Lane Technologies, Inc.&#x2122;
                    </a>
                    <div id={"footerContactUs"}>
                        <Link className={"infoItem"} to={"/contactus/"}>Contact Us</Link>
                        <a role="button" className={"infoItem link"} href="tel:8552725555">855.272.5555</a>
                    </div>
                </div>
                <div id={"footerSocialText"} className={"footerInfo"}>Connect With Us</div>
                <div id={"footerCopyright"} className={"footerInfo"}>
                    <p className={"infoItem"}>
                        Copyright &#xA9; {new Date().getFullYear()} All rights reserved
                    </p>
                    <p className={"infoItem"}>
                        iPhone, iPad, Apple, Android, Windows, Blackberry and other product and service logos are registered
                        trademarks of their respective owners.
                    </p>
                    <p className={"infoItem"}>
                        All screen images simulated.
                    </p>
                </div>
                <div id={"footerIcons"} className={"footerInfo"}>
                    <a
                        className={"iconLink"}
                        href={"https://www.instagram.com/austinlanetech/"}
                        target={"_blank"}
                        rel={"noreferrer noopener"}
                    >
                        <Img
                            className="footIcon"
                            fixed={data.instagram.childImageSharp.fixed}
                            alt="instagram Logo"
                        />
                    </a>
                    <a
                        className={"iconLink"}
                        href={"https://twitter.com/austinlanetech"}
                        target={"_blank"}
                        rel={"noreferrer noopener"}
                    >
                        <Img
                            className="footIcon"
                            fixed={data.twitter.childImageSharp.fixed}
                            alt="twitter Logo"
                        />
                    </a>
                    <a
                        className={"iconLink"}
                        href={"https://www.facebook.com/ALMobile-1508944232507273/"}
                        target={"_blank"}
                        rel={"noreferrer noopener"}
                    >
                        <Img
                            className="footIcon"
                            fixed={data.facebook.childImageSharp.fixed}
                            alt="Facebook Logo"
                        />
                    </a>
                    <a
                        className={"iconLink"}
                        href={"https://www.linkedin.com/company/austin-lane-technologies-inc-/"}
                        target={"_blank"}
                        rel={"noreferrer noopener"}
                    >
                        <Img
                            className="footIcon"
                            fixed={data.linkedin.childImageSharp.fixed}
                            alt="linkedin Logo"
                        />
                    </a>
                    <a
                        className={"iconLink"}
                        href={"https://vimeo.com/user64572932"}
                        target={"_blank"}
                        rel={"noreferrer noopener"}
                    >
                        <Img
                            className="footIcon"
                            fixed={data.vimeo.childImageSharp.fixed}
                            alt="Vimeo Logo"
                        />
                    </a>
                </div>
            </div>
        </footer>
    )
};

export default Footer;
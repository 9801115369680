import React from "react";

const ALButton = ({formReg, disabled, handleSubmit}) => {
    return (
        <div className={"ALButtonContainer"}>
            <button
                name={"ALMobileSubmit"}
                disabled={disabled}
                ref={formReg}
                id={"submitBtn"}
                value={"Submit"}
                onClick={handleSubmit}
            >
                { (disabled) ? "Please Wait" : "Submit"}
            </button>
        </div>
    )
};

export default ALButton;
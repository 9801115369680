import React from "react";
import {Link} from "gatsby";

function drawContents(activeElem) {
    return (
        <div className="subLinkContainer">{
            activeElem.map((opt, index) => {
                return (
                    <Link
                        key={index}
                        className="subLink"
                        to={`${opt.link}`}
                        state={{component: opt.componentID}}
                    >
                        {opt.label}
                    </Link>
                )
            })
        }
        </div>
    )
}

const SubOptions = ({index, enterCallback, leaveCallback, subOptions}) => {
    const hasContents = (subOptions.length > 0);
    return (
        <div
            id={"subOptions"}
            index={index}
            className={`navSubOptions desktop ${(hasContents) ? "active" : ""}`}
            onMouseEnter={ e => enterCallback(e)}
            onMouseLeave={ _ => leaveCallback(-1)}
        >
            {
                (hasContents) ? drawContents(subOptions) : ''
            }
        </div>
    );

};

export default SubOptions;
import React, { useState } from "react";
import {Link} from "gatsby";

import SearchBar from "./searchbar";

function NavComp ({active, navData, dataIndex, onClick: parentCallback = _ => ""}) {
    const {label, subOptions} = navData;
    const [isActive, setActive] = useState(false);

    function showSubOptions() {
        setActive(!isActive);
    }

    return (
        <div
            index={dataIndex}
            className={`mobileMenuItemContainer`}
        >
            <div
                className={`mobileMenuParent link ${isActive || active ? "active" : ""}`}
                onClick={showSubOptions}
            >
                {label}
            </div>
            {
                (subOptions.length > 0)
                    ? <div
                        className={`mobileSubMenu ${isActive || active ? "active" : ""}`}
                        style={{
                            height: (isActive) ? `${subOptions.length * 45}px` : 0
                        }}
                    >
                        {
                            subOptions.map((elem, index) => {
                                return (
                                    <Link
                                        key={index}
                                        to={elem.link}
                                        className={`mobileMenuLabel link`}
                                        state={{component: elem.componentID}}
                                    >
                                        {elem.label}
                                    </Link>
                                )
                            })
                        }
                    </div>
                    : <></>
            }
        </div>
    );
}


const NavBarMobile = ({options}) => {
    const navOptions = [...options];
    const [menuActive, activateMenu] = useState(false);

    function toggleMenu() {
        activateMenu(!menuActive);
    }

    return (
        <div className={"navbar mobile"}>
            <div
                id="menuIcon"
                onClick={toggleMenu}
            >
                <div className={`bar1 ${ (menuActive) ? "active" : "" }`}></div>
                <div className={`bar2 ${ (menuActive) ? "active" : "" }`}></div>
                <div className={`bar3 ${ (menuActive) ? "active" : "" }`}></div>
            </div>
            <div
                id={"mobileMenu"}
                className={`${ (menuActive) ? "active" : "" }`}
            >
                <div id={"mobileMenuContent"}>
                    <div id={"fixedTopRow"}>
                        <SearchBar />
                        <div
                            id={"mobileClose"}
                            onClick={toggleMenu}
                        >
                            <div className={`bar1`}></div>
                            <div className={`bar2`}></div>
                        </div>
                    </div>
                    <div id={"mobileItemsContainer"}>
                        {
                            navOptions.map( (elem, index) => {
                                return (
                                    <NavComp
                                        key={index}
                                        navData={elem}
                                        dataIndex={index}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};

export default NavBarMobile;
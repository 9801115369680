import React from "react";

const ALSelect = ({formReg, options = [], disabled}) => {
    return (
        <div className={"ALSelectContainer"}>
            <div className={"ALSelectLabel"}>ALMobile Service*</div>
            <select
                disabled={disabled}
                name={"ALMobile Service"}
                ref={formReg}
            >
                {
                    options.map((elem, index) =>
                        <option key={index} value={elem.val}>{elem.label}</option>
                    )
                }
            </select>
        </div>
    )
};

export default ALSelect;